<template>
    <v-card
      flat
      class="pa-3 mt-1"
    >
      <v-card-title class="d-flex align-center justify-center py-0">
        <div
          class="d-flex align-center"
        >
          <v-img
            :src="require(`@/assets/images/logos/logo.png`)"
            max-width="300px"
            alt="logo"
            contain
            class="me-0 "
          ></v-img>
        </div>
      </v-card-title>
      <v-form class="multi-col-validation">
        <v-card-text class="pt-1">
          <p class="text-h6 text-center">Add a list of prospects</p>
          <v-row>
            <v-col cols="12" class="py-0 my-1">
              <v-text-field
                v-model="prospectName"
                ref="prospect_name"
                outlined dense
                label="Enter Prospect Name"
              ></v-text-field>
            </v-col>
  
            <v-col cols="12" class="py-0 my-1">
              <v-textarea
                outlined
                v-model="prospectDescription"
                ref="prospect_description"
                name="input-7-4"
                label="Enter Prospect Description"
              ></v-textarea>
            </v-col>
  
            <v-col cols="12" class="py-0 my-1">
              <v-file-input
                show-size accept=".xlsx"
                v-model="prospectFile"
                ref="prospect_file"
                truncate-length="18"
                style="cursor: pointer"
                label="Choose Prospect File"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
  
        <div class=" text-end">
          <spinner v-if="loading" class="text-end"></spinner>
          <v-btn
            v-else
            color="primary"
            class="me-3 mt-3"
            small
            @click="addProspect()"
          >
            <font-awesome-icon icon="fa-solid fa-plus" class="pe-1" />
            Add Prospect List
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </template>
  
  <script>
  // eslint-disable-next-line object-curly-newline
  import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
  import axios from 'axios'
  import Spinner from "@/components/Spinner.vue";
  
  export default {
    components: {
      Spinner,
    },

    data() {
  
      return {
        loading: false,
        prospectName: "",
        prospectFile: [],
        prospectDescription: "",
        // prospectFileTypes: file_types,
  
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      }
    },
    
    mounted(){
        // executes these after the page has been mounted
        this.getProspectType();
        document.title = "Manager: Savings | Add a list of prospect"
    },
  
    methods:{  
      async addProspect(){
        console.log(this.prospectFile)
        if(this.prospectName === ""){
            this.$store.commit('setSnackBarMessage', "Please fill in the prospect name")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.prospect_name.focus()  // this causes a an auto fucos to the element
        }
        else if(this.prospectDescription === ""){
            this.$store.commit('setSnackBarMessage', "Please input the prospect description")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.prospect_description.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.prospectFile){
            this.$store.commit('setSnackBarMessage', "Please upload the prospect file")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.prospect_file.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
  
            let formData = new FormData()
            formData.append('prospectFile', this.prospectFile)
            formData.append('prospectDescription', this.prospectDescription)
            formData.append('prospectName', this.prospectName)
            if(this.$store.state.isSavings){
              formData.append('section', "Savings")
            }
            else if(this.$store.state.isMarketPlace){
              formData.append('section', "Market Place")
            }
            else if(this.$store.state.isNjangi){
              formData.append('section', "Njangi")
            }
            else if(this.$store.state.isFundraising){
              formData.append('section', "Crowd Funding")
            }
            else if(this.$store.state.isMonitor){
              formData.append('section', "Monitor")
            }
        
            await axios
                .post('/api/v1/manager/add/marketing_prospect/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    if(response.data[0].id !== 0){
                        console.log('new prospect:'+response.data[0].id)  // wait after 5000 seconds to redirect user
                        let router = this.$router
                        setTimeout(function(){
                            router.push({ name: 'savings.marketing_prospects'})
                        }, 4000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })

            this.loading = false
        }
      },
    },
  }
  </script>
  